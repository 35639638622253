import React from "react"
import theme from "theme"
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>About Us | Discover Cast Iron Strength</title>
        <meta
          name={"description"}
          content={"Dive Deeper into Your Fitness Journey"}
        />
        <meta property={"og:title"} content={"About Us | Discover Cast Iron Strength"} />
        <meta
          property={"og:description"}
          content={"Dive Deeper into Your Fitness Journey"}
        />
        <meta
          property={"og:image"}
          content={"https://crysanta.com/images/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://crysanta.com/images/icon.jpeg"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://crysanta.com/images/icon.jpeg"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://crysanta.com/images/icon.jpeg"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://crysanta.com/images/icon.jpeg"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://crysanta.com/images/icon.jpeg"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://crysanta.com/images/icon.jpeg"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="url(https://crysanta.com/images/4.jpg) center/cover"
        padding="140px 0"
        sm-padding="40px 0"
        quarkly-title="About-16"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          justify-content="center"
          sm-min-width="280px"
        />
        <Box
          padding="50px 80px 50px 50px"
          color="--dark"
          display="flex"
          flex-direction="column"
          align-items="center"
          max-width="800px"
          background="#ffffff"
          sm-padding="50px 24px 50px 24px"
        >
          <Text margin="0 0 16px 0" as="h2" font="--headline2">
          Explore Our Foundations
          </Text>
          <Hr
            min-height="10px"
            margin="0px 0px 0px 0px"
            border-color="--color-dark"
            border-width="3px 0 0 0"
            max-width="50px"
            width="100%"
          />
          <Text font="--base" text-align="center">
          Cast Iron Strength is not just a gym, it’s a revolution in personal health and fitness. Conceived with the vision of creating a space that transcends traditional gym environments, we focus on integrating every aspect of wellness into our approach. Our mission is to provide a sanctuary for growth, both physical and mental, empowering our members to achieve their fitness and wellness goals in a community that values progress and support.
          </Text>
        </Box>
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override
          slot="SectionContent"
          flex-direction="row"
          md-flex-wrap="wrap"
          flex-wrap="wrap"
        />
        <Box
          width="100%"
          margin="0px 0px 64px 0px"
          md-margin="0px 0px 30px 0px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--dark"
            font="--headline2"
            width="45%"
            lg-width="100%"
            lg-text-align="center"
          >
           Our Expert Trainers
          </Text>
        </Box>
        <Box display="flex" width="100%" md-flex-wrap="wrap">
          <Box
            width="48.5%"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            md-width="100%"
            padding="0px 0px 0px 0px"
            md-padding="0px 0px 0px 0px"
            md-margin="0px 0px 40px 0px"
            margin="0px 3% 0px 0px"
          >
            <Box
              width="100%"
              height="auto"
              overflow-x="hidden"
              overflow-y="hidden"
              position="relative"
              padding="40% 0px 0px 0px"
              border-radius="24px"
              md-margin="0px 0px 20px 0px"
            >
              <Image
                src="https://crysanta.com/images/5.jpg"
                width="100%"
                object-fit="cover"
                position="absolute"
                top={0}
                left={0}
                bottom={0}
                right={0}
                min-height="350px"
              />
            </Box>
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--base"
              md-text-align="center"
            >
              At the heart of Cast Iron Strength are our expert trainers. Each trainer brings a unique set of skills and a personal touch to their training methods, equipped with advanced certifications and a passion for transformative fitness. They are not just instructors – they are motivators and innovators in health, dedicated to tailoring their approach to fit the needs of every member, ensuring optimal results and a rewarding experience.
            </Text>
          </Box>
          <Box
            width="48.5%"
            display="flex"
            flex-direction="column"
            justify-content="space-between"
            md-width="100%"
            padding="0 0px 0 0px"
          >
            <Box
              width="100%"
              height="auto"
              overflow-x="hidden"
              overflow-y="hidden"
              position="relative"
              padding="40% 0px 0px 0px"
              border-radius="24px"
              margin="0px 0px 40px 0px"
              md-margin="0px 0px 20px 0px"
            >
              <Image
                src="https://crysanta.com/images/6.jpg"
                width="100%"
                object-fit="cover"
                position="absolute"
                top={0}
                left={0}
                bottom={0}
                right={0}
                min-height="350px"
              />
            </Box>
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--base"
              md-text-align="center"
            >
              At Cast Iron Strength, we believe in crafting personal success stories. We invite you to become a part of our ever-growing family and start your journey towards a better, fitter you. Here, every workout is a step towards personal excellence, and every member’s success adds to our collective story.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-1"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          align-items="flex-start"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          justify-content="center"
          lg-width="100%"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
        >
          <Box
            width="100%"
            position="relative"
            transform="translateY(0px)"
            hover-transform="translateY(-10px)"
            padding="0px 0px 100% 0px"
            height="auto"
            overflow-x="hidden"
            overflow-y="hidden"
            transition="transform 0.2s ease-in-out 0s"
          >
            <Image
              src="https://crysanta.com/images/7.jpg"
              position="absolute"
              display="block"
              width="100%"
              left={0}
              min-height="100%"
              object-fit="cover"
              top={0}
              right={0}
              bottom={0}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          width="50%"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          lg-order="1"
          flex-direction="column"
          lg-width="100%"
          sm-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Text
            margin="24px 0px 0px 0px"
            color="--darkL2"
            font="--lead"
            lg-text-align="center"
            md-text-align="left"
          >
            What Sets Us Apart?
          </Text>
          <Text
            margin="5px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Innovative Training Programs: Each program is a blend of cutting-edge science and practical experience, designed to push boundaries.
            <br />
            <br />
            Community Events: Regular events that foster strong bonds and a sense of belonging among members.
            <br />
            <br />
            Holistic Health Focus: We incorporate wellness coaching and nutritional advice into our offerings, recognizing that true fitness is multidimensional.
            <br />
            <br />
            Eco-Friendly Space: Our commitment to sustainability is seen in our eco-friendly facilities and practices, promoting a healthier planet as well as healthier bodies.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
